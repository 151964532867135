<script setup lang="ts"></script>

<template>
  <div font-sans transition duration-0 view-transition-app>
    <!-- grid="~ rows-[max-content_1fr]" h-full w-full -->
    <main id="scrollEl" relative w-full bg-111425>
      <!-- of-x-hidden of-y-auto -->
      <div max-w-screen="2xl" ma>
        <keep-alive>
        <slot />
      </keep-alive>
      </div>
    </main>
  </div>
  <ClientOnly>
    <BackTop />
  </ClientOnly>
</template>
